import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';

import useSiteMetadata from '../components/chrome/SiteMetadata';

const RootLayout = ({ children }) => {
    const { title, description } = useSiteMetadata();

    return (
        <div>
            <Helmet>
                <html lang="en" />
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="google-site-verification" content="_CZFTNhhJZ3kNG9GnnQyj1CKdfeo9wuNn0JsB1ox1gs" />

                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}img/favicon-32x32.png`}
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}img/favicon-16x16.png`}
                    sizes="16x16"
                />

                <meta name="theme-color" content="#fff" />

                <meta property="og:type" content="website" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta
                    property="og:image"
                    content={`${withPrefix('/')}img/og-image.png`}
                />
            </Helmet>

            {children}
        </div>
    );
}

export default RootLayout;