import mixpanel from 'mixpanel-browser';

const EVENT_START_PAGE_SESSION = 'Start page session';
const EVENT_END_PAGE_SESSION = 'End page session';

const registerPageView = (location) => {
    mixpanel.track('Page view', { Path: location.pathname });
}

const startPageSession = (location) => {
    const startEventName = `${EVENT_START_PAGE_SESSION}: ${location.pathname}`;
    const endEventName = `${EVENT_END_PAGE_SESSION}: ${location.pathname}`;

    mixpanel.track(startEventName, { Path: location.pathname });
    mixpanel.time_event(endEventName);
}

const endPageSession = (location, transport = 'xhr') => {
    const endEventName = `${EVENT_END_PAGE_SESSION}: ${location.pathname}`;

    mixpanel.track(endEventName, { Path: location.pathname }, {transport: transport});
}

const routeUpdate = (location, prevLocation) => {
    if (prevLocation && location.pathname === prevLocation.pathname) {
        return;
    }

    if (prevLocation) {
        endPageSession(prevLocation);
    }

    registerPageView(location);
    startPageSession(location);
}

const trackPageViews = () => {
    document.addEventListener("visibilitychange", () => {
        if (document.visibilityState === "hidden") {
            endPageSession(document.location, 'sendBeacon');
        }
        else {
            startPageSession(document.location);
        }
    });
}

export {
    routeUpdate,
    trackPageViews
}