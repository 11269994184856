const React = require("react");
const { default: RootLayout } = require('./src/layouts/RootLayout');
const { persistUtmContext } = require('./src/utils/utmContext');
const { registerIdentityFromUrl } = require("./src/utils/identity");
const { routeUpdate, trackPageViews } = require("./src/utils/pageViews");

exports.onClientEntry = () => {
    trackPageViews();

    registerIdentityFromUrl();
    persistUtmContext();

    addYabloMessenger();
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
    routeUpdate(location, prevLocation);
}

exports.wrapPageElement = ({ element, props }) => {
    return <RootLayout {...props}>{element}</RootLayout>
}

const addYabloMessenger = () => {
    const script = document.createElement('script');
    script.src = 'https://widgets.yablo.io/yablo-sdk.js';
    script.async = true;
    script.onload = () => {
        window.yablo.onReady().then(() => {
            window.yablo.mount({ "tenant": "yablo.io", "channel": "uxmnjsfowyaiegc", "style": { "accentColor": "#45B18E" } });
        });
    };
    document.body.appendChild(script);
}